import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Card, CardBody, CardTitle } from "reactstrap";
import Modal from "../aboutus/modal/index";

const TeamMain = ({ team }) => {
  const [modal, setModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const toggle = (url) => {
    setVideoUrl(url);
    setModal(!modal);
  };
  return (
    <div className="container pb-5">
      <div className="row pt-5 pb-2">
        {team.map((member, index) => (
          <div key={index} className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <Card className="profile-card">
              <GatsbyImage
                className="profile-img"
                loading="eager"
                objectFit="contain"
                image={member.profilePicture.asset.gatsbyImageData}
                alt={member.name}
              />
              <CardBody>
                <CardTitle
                  className="profile-title p-0"
                  style={{ fontWeight: "bold", marginBottom: "20px" }}
                  tag="h5"
                >
                  <strong>
                    {member.name} – {member.designation}
                  </strong>
                </CardTitle>
                {member.introvideo && (
                  <button
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#000",
                    }}
                    onClick={() => toggle(member.introvideo)}
                  >
                    Intro Video
                  </button>
                )}
                {member.introtitle && (
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#727272",
                    }}
                    tag="h6"
                  >
                    <strong>{member.introtitle}</strong>
                  </h6>
                )}
                {member.cell && (
                  <a href={`tel:${member.cell}`} className="profile-text">
                    <strong>Cell:</strong>
                    {member.cell}
                  </a>
                )}
                {member.office && (
                  <a href={`tel:${member.office}`} className="profile-text">
                    <strong>Office:</strong>
                    {member.office}
                  </a>
                )}
                {member.email && (
                  <a href={`mailto:${member.email}`} className="profile-text ">
                    <strong>Email:</strong>
                    {member.email}
                  </a>
                )}
              </CardBody>
            </Card>
          </div>
        ))}
      </div>
      {!!modal && <Modal modal={modal} toggle={toggle} videourl={videoUrl} />}
    </div>
  );
};

export default TeamMain;

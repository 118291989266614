import React, { useState } from "react";
import { SEO } from "../components";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";
import FooterHeadline from "../components/layout/footerheadline";
import AboutUsMain from "../components/aboutus/about-us-main";
import RealEstateSection from "../components/layout/real-estate-section";
import MailSection from "../components/aboutus/about-us-mail-section";
import Form from "../components/aboutus/about-us-from";
import classNames from "classnames";
import { Container } from "reactstrap";
import SideBar from "../components/layout/sidebar/sidebar";
import Topbar from "../components/layout/content/topbar";
import image from "../images/team1.jpg";
import TeamMain from "../components/team/team-main";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { graphql } from "gatsby";
import Modal from "../components/aboutus/modal";

const TheTeam = ({ data }) => {
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const { ref, inView, entry } = useInView();
  const seo = data.sanitySeo;
  const sanityTeam = data.allSanityTeam.nodes;
  const banner = data.sanityBanners.banner.asset.url;

  const handleBrightness = () => {
    if (sidebarIsOpen) {
      return "brightness(50%)";
    } else {
      return "brightness(100%)";
    }
  };
  return (
    <div
      style={{
        maxWidth: "100vw",
        width: "100%",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ldSchema={seo.ldSchema}
      />
      <div className="App wrapper" id="top">
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Container
          fluid
          className={classNames("p-0 m-0", { "is-open": sidebarIsOpen })}
          style={{
            filter: `${handleBrightness()}`,
          }}
        >
          <Header>
            {!sidebarIsOpen ? <Topbar toggleSidebar={toggleSidebar} /> : null}
          </Header>
          <div className="margin-class"></div>
          <AboutUsMain
            fontSize="35px"
            title="Pamela Hudson Real Estate"
            bread="THE TEAM"
            buttontext="Intro Video"
            onClick={toggle}
            src={banner}
            cover="cover"
            // position="-40% 30%"
          />
          {!!modal && (
            <Modal
              modal={modal}
              toggle={toggle}
              videourl="https://cdn.sanity.io/files/36ueq3h7/production/30a8a507ec81fee1622c23b87d2bdb0e9938ff17.mp4"
            />
          )}
          <div
            style={{
              backgroundColor: "#f6f6f6",
            }}
          >
            <TeamMain team={sanityTeam} />
          </div>
          <div ref={ref}>
            <Form />
            <MailSection />
            <div
              style={{
                backgroundColor: "#f6f6f6",
              }}
            >
              <RealEstateSection mendocinoestate={data.sanityMendocinoestate} />
            </div>
            <Footer />
            <div
              style={{
                borderTop: "1px solid green",
              }}
            >
              <FooterHeadline />
            </div>
          </div>
        </Container>
      </div>
      {inView && (
        <div>
          <p
            className="back-to-top in scroll-up-button"
            onClick={() => {
              scrollTo("#top");
            }}
          >
            <FontAwesomeIcon
              className="scroll-up-button-icon mt-2"
              icon={faAngleUp}
            />
          </p>
        </div>
      )}
    </div>
  );
};
export default TheTeam;

export const query = graphql`
  {
    sanityBanners(id: { eq: "-cce10bec-185b-5f62-a220-4773c31fe759" }) {
      id
      pageTitle
      banner {
        asset {
          url
        }
      }
    }
    allSanityTeam(sort: { fields: _createdAt, order: ASC }) {
      nodes {
        profilePicture {
          asset {
            gatsbyImageData
          }
        }
        name
        cell
        office
        email
        designation
        introvideo
        introtitle
      }
    }
    sanitySeo(pagehandle: { eq: "the-team" }) {
      title
      description
      keywords
      ldSchema
    }
    sanityMendocinoestate(id: { eq: "-40ab1577-317f-5cc8-a7e0-f3a4503f46d9" }) {
      title
      description
      image {
        asset {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: WEBP
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;
